const baseURLVer1 = `/api/v1/comment`;

export default {
  /**
   * @Get 댓글 목록 조회
   */
  COMMENT_LIST: `${baseURLVer1}/comments`,
  /**
   * @POST 댓글 생성
   */
  COMMENT_CREATE: `${baseURLVer1}/create`,
  /**
   * @param commentId
   * @Get 댓글 조회
   * @Delete 댓글 삭제
   */
  COMMENT_DETAIL: (commentId: string) => `${baseURLVer1}/${commentId}`,
  /**
   * @Post 댓글 반응 업데이트
   */
  COMMENT_FEEDBACK: `${baseURLVer1}/feedback`,
};
