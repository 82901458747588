import { BoardParams } from '@/hooks/apiHooks';
import { BOARD_URL } from '@/const/apiList';

const boardParamMap = (key: keyof BoardParams) => {
  switch (key) {
    case 'boardNo':
      return 'n';
    case 'category':
      return 'c';
    case 'documentNo':
      return 'd';
  }
};
export const makeBoardUrl = (obj: BoardParams) => {
  const newObj = Object.entries(obj).map(([k, v]) => [
    boardParamMap(k as keyof BoardParams),
    v,
  ]);
  const params = new URLSearchParams(Object.fromEntries(newObj));
  return `${BOARD_URL}?${params.toString()}`;
};

type BaseValue = string | number | boolean;

export const queryBuilder = (
  payload: Record<string, BaseValue | BaseValue[]>,
  options?: {
    includeQuestionMark?: boolean;
    filterNull?: boolean;
  },
): string => {
  const { includeQuestionMark, filterNull } = options ?? {
    includeQuestionMark: false,
    filterNull: true,
  };
  const queryString = Object.entries(payload)
    .filter(([_, value]) =>
      filterNull ? value !== null && value !== undefined : value !== undefined,
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(
          `${Array.isArray(value) ? value.join(',') : value}`,
        )}`,
    )
    .join('&');

  if (!queryString) return '';

  return includeQuestionMark ? `?${queryString}` : queryString;
};
