import { useGetFileLink, useUploadFile } from '@/hooks/apiHooks';
import { uuid4 } from '@sentry/utils';

export interface ExtendFile {
  id: string;
  name: string;
  path?: string;
  preview?: string;
  status: 'new' | 'deleted' | 'default';
  type: string;
  isUploaded: boolean;
  file?: File;
}

const FORMAT_PDF = ['pdf'];
const FORMAT_TEXT = ['txt'];
const FORMAT_PHOTOSHOP = ['psd'];
const FORMAT_WORD = ['doc', 'docx'];
const FORMAT_EXCEL = ['xls', 'xlsx'];
const FORMAT_ZIP = ['zip', 'rar', 'iso'];
const FORMAT_ILLUSTRATOR = ['ai', 'esp'];
const FORMAT_POWERPOINT = ['ppt', 'pptx'];
const FORMAT_AUDIO = ['wav', 'aif', 'mp3', 'aac'];
const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png', 'svg'];
const FORMAT_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm'];

const iconUrl = (icon: string) => `/assets/icons/files/${icon}.svg`;

// ----------------------------------------------------------------------

export function fileFormat(fileUrl: string | undefined) {
  let format;

  switch (fileUrl?.includes(fileTypeByUrl(fileUrl))) {
    case FORMAT_TEXT.includes(fileTypeByUrl(fileUrl)):
      format = 'txt';
      break;
    case FORMAT_ZIP.includes(fileTypeByUrl(fileUrl)):
      format = 'zip';
      break;
    case FORMAT_AUDIO.includes(fileTypeByUrl(fileUrl)):
      format = 'audio';
      break;
    case FORMAT_IMG.includes(fileTypeByUrl(fileUrl)):
      format = 'image';
      break;
    case FORMAT_VIDEO.includes(fileTypeByUrl(fileUrl)):
      format = 'video';
      break;
    case FORMAT_WORD.includes(fileTypeByUrl(fileUrl)):
      format = 'word';
      break;
    case FORMAT_EXCEL.includes(fileTypeByUrl(fileUrl)):
      format = 'excel';
      break;
    case FORMAT_POWERPOINT.includes(fileTypeByUrl(fileUrl)):
      format = 'powerpoint';
      break;
    case FORMAT_PDF.includes(fileTypeByUrl(fileUrl)):
      format = 'pdf';
      break;
    case FORMAT_PHOTOSHOP.includes(fileTypeByUrl(fileUrl)):
      format = 'photoshop';
      break;
    case FORMAT_ILLUSTRATOR.includes(fileTypeByUrl(fileUrl)):
      format = 'illustrator';
      break;
    default:
      format = fileTypeByUrl(fileUrl);
  }

  return format;
}

// ----------------------------------------------------------------------

export function fileThumb(fileUrl: string) {
  let thumb;

  switch (fileFormat(fileUrl)) {
    case 'folder':
      thumb = iconUrl('ic_folder');
      break;
    case 'txt':
      thumb = iconUrl('ic_txt');
      break;
    case 'zip':
      thumb = iconUrl('ic_zip');
      break;
    case 'audio':
      thumb = iconUrl('ic_audio');
      break;
    case 'video':
      thumb = iconUrl('ic_video');
      break;
    case 'word':
      thumb = iconUrl('ic_word');
      break;
    case 'excel':
      thumb = iconUrl('ic_excel');
      break;
    case 'powerpoint':
      thumb = iconUrl('ic_power_point');
      break;
    case 'pdf':
      thumb = iconUrl('ic_pdf');
      break;
    case 'photoshop':
      thumb = iconUrl('ic_pts');
      break;
    case 'illustrator':
      thumb = iconUrl('ic_ai');
      break;
    case 'image':
      thumb = iconUrl('ic_img');
      break;
    default:
      thumb = iconUrl('ic_file');
  }
  return thumb;
}

// ----------------------------------------------------------------------

export function fileTypeByUrl(fileUrl = '') {
  return (fileUrl && fileUrl.split('.').pop()) || '';
}

// ----------------------------------------------------------------------

export function fileNameByUrl(fileUrl: string) {
  return fileUrl.split('/').pop();
}

export default function getFileData(file: ExtendFile | string, index?: number) {
  if (typeof file === 'string') {
    return {
      key: index ? `${file}-${index}` : file,
      name: fileNameByUrl(file) ?? '',
      path: file,
      type: fileTypeByUrl(file),
      preview: file,
    };
  }

  return {
    key: index ? `${file.name}-${index}` : file.name,
    name: file.name,
    path: file.path,
    type: file.type,
    preview: file.preview,
  };
}

export function validateFile(
  files: File[],
  options: {
    maxSize?: number;
    maxLength?: number;
    isOnlyImage?: boolean;
    existFileLength?: number;
  },
) {
  const { maxSize, maxLength, isOnlyImage, existFileLength } = options;

  if (isOnlyImage && files.some((file) => !file.type.startsWith('image/'))) {
    throw new Error('이미지만 업로드 가능합니다.');
  }

  if (maxSize && files.some((file) => file.size > maxSize)) {
    const formatFileSize = (sizeInBytes: number): string => {
      const units = ['B', 'KB', 'MB', 'GB', 'TB'];
      let size = sizeInBytes;
      let unitIndex = 0;

      while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
      }

      if (unitIndex === units.length - 1 && size >= 1024) {
        return '';
      }

      return `${size.toFixed(2)}${units[unitIndex]}`;
    };

    const convertedSize = formatFileSize(maxSize);
    throw new Error(
      convertedSize
        ? `첨부한 파일이 용량 제한인 ${convertedSize}를 초과했어요. 파일 용량 확인 후 다시 시도해 주세요.`
        : `첨부한 파일이 용량 제한을 초과했어요. 파일 용량 확인 후 다시 시도해 주세요.`,
    );
  }

  if (maxLength && (existFileLength || 0) + files.length > maxLength) {
    throw new Error(`최대 ${maxLength}개의 파일만 업로드 가능합니다.`);
  }
}

export const getMimeTypeByExtension = (filename: string) => {
  const mimeTypes: { [key: string]: string } = {
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.gif': 'image/gif',
    '.webp': 'image/webp',
    '.pdf': 'application/pdf',
    '.txt': 'text/plain',
    '.html': 'text/html',
    '.css': 'text/css',
    '.js': 'application/javascript',
    '.json': 'application/json',
    '.csv': 'text/csv',
    '.mp4': 'video/mp4',
    '.mp3': 'audio/mpeg',
    '.wav': 'audio/wav',
    '.zip': 'application/zip',
    '.rar': 'application/vnd.rar',
    '.7z': 'application/x-7z-compressed',
  };
  const ext = filename.slice(filename.lastIndexOf('.')).toLowerCase();
  return mimeTypes[ext] || 'application/octet-stream';
};

export const base64ToFile = (base64String: string, fileName: string): File => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const uploadImage = async (blob: File | Blob) => {
  const uploadData = await useUploadFile(blob as File);
  const downLoadData = await useGetFileLink(uploadData.data.uuid);
  return {
    url: downLoadData.data.url,
    name: uploadData.data.fileName,
  };
};

export type RemoteFile = {
  readonly link: string;
  readonly name: string;
};

export const remoteFileToExtendFile = (remoteFile: RemoteFile): ExtendFile => {
  return {
    id: uuid4(),
    name: remoteFile.name,
    path: remoteFile.link,
    preview: remoteFile.link,
    status: 'new',
    isUploaded: true,
    type: getMimeTypeByExtension(remoteFile.name),
  };
};

export const extendFileToRemoteFile = (files: ExtendFile[]) =>
  files
    .filter(({ isUploaded }) => isUploaded)
    .map((file) => ({
      name: file.name ?? '',
      link: file.path ?? '',
    }));
