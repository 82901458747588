import {
  BoardFormData,
  BooleanOption,
} from '@/components/Board/BoardForm/@hooks/useBoardForm';
import { CategoryType, MyInfo } from '@/types/api';
import {
  BoardCommentSection,
  BoardDetailData,
  BoardViewType,
} from '@/types/board';
import {
  format,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';
import {
  extendFileToRemoteFile,
  remoteFileToExtendFile,
} from '@/components/file-thumbnail/utils';
import { BoardPost } from '@/hooks/apiHooks';

export const boardFormDataToBoardDetailPreview = (
  data: BoardFormData,
  fullCategoryList: CategoryType[],
  userInfo: MyInfo,
): BoardDetailData => {
  const selectedCategories = data.categories
    .map((c) => fullCategoryList.find((n) => n.id === c))
    .filter((item): item is CategoryType => item !== undefined) // undefined 필터링 및 타입 좁히기
    .map(({ id, name }) => ({ id, name }));

  const useUploadTime = data.useUploadTime === BooleanOption.TRUE;

  const createdTime = data.createdTime ?? new Date();
  return {
    id: data.id ?? '',
    commentGroupId: '',
    categories: selectedCategories,
    subject: data.subject,
    content: data.content,
    readersCount: 0,
    watchCount: 0,
    createdBy: {
      ...userInfo,
      nickName: userInfo.name,
    },
    createdAt: formatTime(createdTime),
    updatedAt: formatTime(data.updatedTime ?? new Date()),
    feedback: {
      isRecommend: false,
      isLike: false,
      isBookmark: false,
      likesCount: 0,
      likes: [],
      recommendsCount: 0,
      recommends: [],
    },
    config: {
      isCommentEnable: true,
      isDeleteEnable: false,
      commentSection: data.commentPosition as BoardCommentSection,
      feedbackSettings: { like: true, recommend: true, bookmark: true },
      viewType: data.useImageSlides
        ? BoardViewType.SLIDE
        : (BoardViewType.NORMAL as BoardViewType),
      isTemporary: false,
      isImportant: data.isImportant === BooleanOption.TRUE,
      editorType: 'Lexical',
      displaySections:
        data.displaySections === 'none' ? [] : [data.displaySections],
      displayStartedAt: useUploadTime
        ? formatTime(data.displayTime)
        : formatTime(createdTime),
    },
    files: {
      attachments:
        data.displaySections === 'none'
          ? []
          : extendFileToRemoteFile(data.attachments),
      slideImages: data.useImageSlides
        ? extendFileToRemoteFile(data.imageSlides)
        : [],
      thumbnails:
        useUploadTime && data.thumbnailFile
          ? extendFileToRemoteFile([data.thumbnailFile])
          : [],
    },
  };
};

export const boardFormDataToPayload = (
  data: BoardFormData,
  isTemporary: boolean,
) => {
  const dateToPayloadDate = (date?: Date | null) =>
    formatTime(date) || undefined;

  const getDisplaySectionOptions = (
    displayOption: BoardFormData['displaySections'],
    useThumbnailDisplayTime: boolean,
  ) => {
    if (displayOption === 'none') {
      return {
        thumbnails: [],
        homeDisplayStartedAt: dateToPayloadDate(data.createdTime),
        homeDisplayEndedAt: undefined,
      };
    } else {
      const thumbnails = data.thumbnailFile
        ? extendFileToRemoteFile([data.thumbnailFile])
        : [];
      const homeDisplayStartedAt = useThumbnailDisplayTime
        ? dateToPayloadDate(data.thumbnailDisplayStartedAt)
        : undefined;
      const homeDisplayEndedAt = useThumbnailDisplayTime
        ? dateToPayloadDate(data.thumbnailDisplayEndAt)
        : undefined;

      return {
        thumbnails,
        homeDisplayStartedAt:
          homeDisplayStartedAt ?? dateToPayloadDate(data.createdTime),
        homeDisplayEndedAt,
      };
    }
  };

  const { thumbnails, homeDisplayStartedAt, homeDisplayEndedAt } =
    getDisplaySectionOptions(
      data.displaySections,
      data.useThumbnailDisplayTime === BooleanOption.TRUE,
    );

  return {
    subject: data.subject,
    content: data.content,
    files: {
      attachments: extendFileToRemoteFile(data.attachments),
      thumbnails: thumbnails,
      slideImages: data.useImageSlides
        ? extendFileToRemoteFile(data.imageSlides)
        : [],
    },
    categories: data.categories,
    config: {
      isTemporary,
      viewType: (data.imageSlides.length > 0
        ? 'slide'
        : 'normal') as BoardPost['config']['viewType'],
      displaySections:
        data.displaySections === 'none' ? [] : [data.displaySections],
      displayStartedAt:
        data.useUploadTime === BooleanOption.TRUE
          ? dateToPayloadDate(data.displayTime)
          : dateToPayloadDate(data.createdTime),
      commentSection: data.commentPosition,
      isImportant: data.isImportant === BooleanOption.TRUE,
      editorType: 'LexicalEditor' as BoardPost['config']['editorType'],
      homeDisplayStartedAt,
      homeDisplayEndedAt,
    },
  };
};

export const boardDetailDataBoardFormData = (
  isTemporary: boolean,
  boardDetailData?: BoardDetailData,
  fullCategoryList?: CategoryType[],
): BoardFormData | undefined => {
  const getCategories = (
    selectedCategories: BoardDetailData['categories'],
    fullCategoryList: CategoryType[],
  ) => {
    if (selectedCategories.length === 0) {
      return [];
    }

    const result: CategoryType[] = [];
    selectedCategories.forEach((selected) =>
      result.push(...fullCategoryList.filter((cat) => cat.id === selected.id)),
    );
    return result.map(({ id }) => id);
  };

  const payloadDateToDate = (date?: string | null) =>
    date ? new Date(date) : null;

  if (boardDetailData && fullCategoryList) {
    const {
      id,
      subject,
      content,
      categories: selectedCategories,
      files: { slideImages, attachments, thumbnails },
      config: {
        displayStartedAt,
        homeDisplayStartedAt,
        homeDisplayEndedAt,
        isImportant,
        commentSection,
        displaySections,
      },
      createdAt,
      updatedAt,
    } = boardDetailData;

    const displaySectionType =
      displaySections.length === 0
        ? 'none'
        : (displaySections[0] as BoardFormData['displaySections']) ?? 'none';

    const hasDisplayTimeSet =
      isTemporary && displayStartedAt === createdAt
        ? false
        : displayStartedAt !== createdAt;

    const thumbnailDisplayHasSet =
      homeDisplayStartedAt && homeDisplayStartedAt !== createdAt;
    const thumbnailDisplayStartedAt = thumbnailDisplayHasSet
      ? payloadDateToDate(homeDisplayStartedAt)
      : null;

    const thumbnailDisplayEndAt = payloadDateToDate(homeDisplayEndedAt);

    const useThumbnailDisplayTime = Boolean(
      isTemporary
        ? thumbnailDisplayStartedAt || thumbnailDisplayEndAt
        : thumbnailDisplayStartedAt && thumbnailDisplayEndAt,
    );

    const thumbnail = thumbnails?.[0];

    return {
      id,
      subject: subject || '',
      content: content || '',
      categories: getCategories(selectedCategories, fullCategoryList),
      useImageSlides: slideImages.length > 0,
      imageSlides: slideImages.map(remoteFileToExtendFile),
      attachments: attachments.map(remoteFileToExtendFile),
      useUploadTime: hasDisplayTimeSet
        ? BooleanOption.TRUE
        : BooleanOption.FALSE,
      displayTime: hasDisplayTimeSet
        ? new Date(displayStartedAt)
        : setMilliseconds(
            setSeconds(setMinutes(setHours(new Date(), 9), 0), 0),
            0,
          ),
      isImportant: isImportant ? BooleanOption.TRUE : BooleanOption.FALSE,
      commentPosition: commentSection,
      displaySections: displaySectionType,
      thumbnailFile:
        displaySectionType !== 'none' && thumbnail
          ? remoteFileToExtendFile(thumbnail)
          : null,
      useThumbnailDisplayTime: useThumbnailDisplayTime
        ? BooleanOption.TRUE
        : BooleanOption.FALSE,
      thumbnailDisplayStartedAt,
      thumbnailDisplayEndAt,
      createdTime: new Date(createdAt),
      updatedTime: new Date(updatedAt),
      useThumbnail:
        displaySectionType === 'bannerSection' ||
        displaySectionType === 'rightFixSection' ||
        (displaySectionType === 'rightSection' && thumbnail)
          ? BooleanOption.TRUE
          : BooleanOption.FALSE,
    };
  }
};

export const formatTime = (time?: Date | null) =>
  time ? format(time, 'yyyy-MM-dd HH:mm:ss') : '';
