import { setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';
import { ExtendFile } from '@/components/file-thumbnail/utils';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export enum BooleanOption {
  TRUE = 'true',
  FALSE = 'false',
}

export type InitialBoardFormData = {
  subject: string;
  categories: string[];
  content: string;
  useImageSlides: boolean;
  imageSlides: ExtendFile[];
  attachments: ExtendFile[];
  useUploadTime: BooleanOption;
  displayTime: null | Date;
  isImportant: BooleanOption;
  commentPosition: 'footerSection' | 'rightSection';
  displaySections:
    | 'bannerSection'
    | 'rightFixSection'
    | 'rightSection'
    | 'importantSection'
    | 'interviewSection'
    | 'none';
  useThumbnail: BooleanOption;
  thumbnailFile: ExtendFile | null;
  useThumbnailDisplayTime: BooleanOption;
  thumbnailDisplayStartedAt: null | Date;
  thumbnailDisplayEndAt: null | Date;
};

export type BoardFormData = InitialBoardFormData & {
  id: string;
  createdTime: Date;
  updatedTime: Date;
};

const defaultValues: InitialBoardFormData = {
  subject: '',
  content: '',
  categories: [],
  useImageSlides: false,
  imageSlides: [],
  attachments: [],
  useUploadTime: BooleanOption.FALSE,
  displayTime: setMilliseconds(
    setSeconds(setMinutes(setHours(new Date(), 9), 0), 0),
    0,
  ),
  isImportant: BooleanOption.FALSE,
  commentPosition: 'footerSection',
  displaySections: 'none',
  useThumbnail: BooleanOption.FALSE,
  thumbnailFile: null,
  useThumbnailDisplayTime: BooleanOption.FALSE,
  thumbnailDisplayStartedAt: null,
  thumbnailDisplayEndAt: null,
};

const ExtendFileScheme = {
  key: Yup.string(),
  name: Yup.string(),
  path: Yup.string().optional(),
  preview: Yup.string().optional(),
  status: Yup.string().oneOf(['new', 'deleted', 'default']),
  type: Yup.string().optional(),
};

const Schema = Yup.object().shape({
  id: Yup.string().optional(),
  subject: Yup.string()
    .required('제목을 입력해주세요.')
    .transform((value) => value.trim())
    .max(100, '제목은 최대 100자까지 입력 가능합니다.'),
  categories: Yup.array().of(Yup.string()).min(1, '카테고리를 선택해주세요.'),
  content: Yup.string().optional(),
  imageSlides: Yup.array()
    .of(Yup.object().shape(ExtendFileScheme))
    .when('useImageSlides', {
      is: (value: string) => value,
      then: (value) => value.min(1, '이미지를 선택해주세요.'),
      otherwise: (value) => value.min(0),
    }),
  attachments: Yup.array().of(Yup.object().shape(ExtendFileScheme)),
  displayTime: Yup.date()
    .nullable()
    .when('useUploadTime', {
      is: (value: string) => value === BooleanOption.TRUE,
      then: (displayTime) => displayTime.required(),
      otherwise: (displayTime) => displayTime.nullable(),
    }),
  isImportant: Yup.string().oneOf([BooleanOption.TRUE, BooleanOption.FALSE]),
  commentPosition: Yup.string()
    .oneOf(['footerSection', 'rightSection'])
    .required(),
  displaySections: Yup.string()
    .oneOf([
      'importantSection',
      'interviewSection',
      'bannerSection',
      'rightFixSection',
      'rightSection',
      'none',
    ])
    .required(),
  thumbnailFile: Yup.object()
    .shape(ExtendFileScheme)
    .nullable()
    .when('useThumbnail', {
      is: (value: BooleanOption) => value === BooleanOption.TRUE,
      then: (value) => value.required(),
      otherwise: (value) => value.nullable(),
    }),
  thumbnailDisplayStartedAt: Yup.date()
    .nullable()
    .when('useThumbnailDisplayTime', {
      is: (value: BooleanOption) => value === BooleanOption.TRUE,
      then: (schema) =>
        schema
          .required('시작일을 입력해주세요.')
          .test(
            'is-before-end',
            '종료일이 시작일보다 빠르게 설정되었어요.',
            function (value, context) {
              const {
                parent: { thumbnailDisplayEndAt },
              } = context;
              return (
                !value ||
                !thumbnailDisplayEndAt ||
                value <= thumbnailDisplayEndAt
              );
            },
          ),
      otherwise: (schema) => schema.nullable(),
    }),

  thumbnailDisplayEndAt: Yup.date()
    .nullable()
    .when('useThumbnailDisplayTime', {
      is: (value: BooleanOption) => value === BooleanOption.TRUE,
      then: (schema) =>
        schema
          .required('종료일을 입력해주세요.')
          .test('is-after-start', '', function (value, context) {
            const {
              parent: { thumbnailDisplayStartedAt },
            } = context;

            return (
              !value ||
              !thumbnailDisplayStartedAt ||
              value >= thumbnailDisplayStartedAt
            );
          }),
      otherwise: (schema) => schema.nullable(),
    }),
});

const useBoardForm = () => {
  return useForm<BoardFormData>({
    resolver: yupResolver(Schema),
    defaultValues,
    mode: 'onChange',
  });
};

export default useBoardForm;
