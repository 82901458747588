const baseURLVer1 = `/api/v1/board`;

export default {
  /**
   * @PUT 게시글 읽음 처리
   */
  BOARD_READ: (documentId: string) => `${baseURLVer1}/read/${documentId}`,
  /**
   * @GET 게시글 검색
   */
  BOARD_SEARCH: baseURLVer1,
  /**
   * @param documentId
   * @GET 게시글 조회
   * @DELETE 게시글 삭제
   */
  BOARD_DETAIL: (documentId: string) => `${baseURLVer1}/${documentId}`,
  /**
   * @param documentId 게시글 아이디
   * @GET 피드백 유저 정보 조회
   * @PUT 피드백 활성화
   * @DELETE 피드백 활성화 취소
   */
  BOARD_FEEDBACK: (documentId: string) =>
    `${baseURLVer1}/feedback/${documentId}`,
  /**
   * @Get 게시글 카테고리 조회
   */
  BOARD_CATEGORY_LIST_FILTER: (params = '') =>
    `${baseURLVer1}/category/list/filter${params}`,
  /**
   * @POST 게시글 작성
   */
  BOARD_CREATE: `${baseURLVer1}/create`,
  /**
   * @PUT 게시글 수정
   */
  BOARD_UPDATE: `${baseURLVer1}/update`,
};
