import { User } from '@/types/api';
import { RemoteFile } from '@/components/file-thumbnail/utils';

//TODO @자올 나중에 작성/수정쪽 타입 정리하기
export enum BoardCommentSection {
  BOTTOM = 'footerSection',
  RIGHT = 'rightSection',
}
export enum BoardViewType {
  NORMAL = 'normal',
  SLIDE = 'slide',
}

export interface BoardDetailData {
  id: string;
  commentGroupId: string;
  categories: { id: string; name: string }[];
  subject: string;
  content: string;
  readersCount: number;
  watchCount: number;
  createdBy: User;
  createdAt: string;
  updatedAt: string;
  feedback: {
    isRecommend: boolean;
    isLike: boolean;
    isBookmark: boolean;
    likesCount: number;
    likes: User[];
    recommendsCount: number;
    recommends: User[];
  };
  config: {
    isCommentEnable: boolean;
    isDeleteEnable: boolean;
    commentSection: BoardCommentSection;
    feedbackSettings: { like: boolean; recommend: boolean; bookmark: boolean };
    viewType: BoardViewType;
    isTemporary: boolean;
    isImportant: boolean;
    displayStartedAt: string;
    homeDisplayStartedAt?: string;
    homeDisplayEndedAt?: string;
    editorType?: string;
    displaySections: string[];
  };
  files: {
    attachments: RemoteFile[];
    slideImages: RemoteFile[];
    thumbnails: RemoteFile[];
  };
}
