import { fetchApi } from '@/utils/api/customAxios';
import process from 'process';
import { ApiTokenType } from '@/types/api';
import { AxiosInstance } from 'axios';

export const getNestedMetaDataFetcher = <T = unknown>(
  url: string,
  instance?: AxiosInstance,
  tokenType?: ApiTokenType,
  extraHeaders?: Record<string, unknown>,
) =>
  fetchApi<T>({
    method: 'GET',
    instance,
    apiTokenType: tokenType,
    extraHeaders,
    url,
  }).then((data) => data);

export const getNestedDataFetcher = <T = unknown>(
  url: string,
  instance?: AxiosInstance,
  tokenType?: ApiTokenType,
  extraHeaders?: Record<string, unknown>,
) =>
  fetchApi<{ data: T }>({
    method: 'GET',
    instance,
    apiTokenType: tokenType,
    extraHeaders,
    url,
  }).then((data) => data.data);

export const getSocialDataNestedDataFetcherWithMeta = <T = unknown>(
  url: string,
  instance?: AxiosInstance,
  tokenType?: ApiTokenType,
  extraHeaders?: Record<string, unknown>,
) =>
  fetchApi<{ data: T; meta: any }>({
    method: 'GET',
    instance,
    apiTokenType: tokenType,
    extraHeaders,
    url: url,
  }).then((data) => {
    return [
      {
        content: data.data,
        meta: data.meta,
      },
    ];
  });

export const getNestedNotificationDataFetcher = <T = unknown>(url: string) =>
  fetchApi<{ data: T }>({
    method: 'GET',
    url,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
  }).then((res) => res.data);

export const getDataFetcher = <T extends Record<string, unknown>>(
  url: string,
) =>
  fetchApi<T>({
    method: 'GET',
    url,
  });

export const postDataFetcher = <
  T extends Record<string, unknown>,
  E extends Record<string, unknown> = {},
>(
  url: string,
  option: T,
) =>
  fetchApi<E>({
    method: 'POST',
    url,
    data: option,
  });

export const deleteDataFetcher = <T extends Record<string, unknown>>(
  url: string,
  option?: T,
) =>
  fetchApi<T>({
    method: 'DELETE',
    url,
    data: option,
  });

export const patchDataFetcher = <
  T extends Record<string, unknown>,
  E extends Record<string, unknown> = {},
>(
  url: string,
  option: T,
) =>
  fetchApi<E>({
    method: 'PATCH',
    url,
    data: option,
  });

export const putDataFetcher = <
  T extends Record<string, unknown>,
  E extends Record<string, unknown> = {},
>(
  url: string,
  option: T,
) =>
  fetchApi<E>({
    method: 'PUT',
    url,
    data: option,
  });

export const makeQueryString = (
  obj: { [key: string]: any },
  addQuestion: boolean,
  isBooleanToNumber?: boolean,
) => {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    if (isBooleanToNumber && typeof value === 'boolean') {
      value = value ? 1 : 0;
      params.append(key, value);
    } else if (value) {
      params.append(key, value);
    }
  });
  return (addQuestion ? '?' : '') + params.toString();
};
