import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

export const formatNumberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dateFormat = (date: string) => {
  return `${format(new Date(date), 'MM월dd일')}(${format(
    new Date(date),
    'EEE',
    {
      locale: ko,
    },
  )})`;
};

export const yearDayFormat = (date: string) => {
  return `${format(new Date(date), 'yyyy.MM.dd')} (${format(
    new Date(date),
    'EEE',
    {
      locale: ko,
    },
  )})`;
};

export const getNowFormat = (formatStr: string) => {
  return `${format(new Date(), formatStr)}`;
};

export const getDateFormat = (date: string, formatStr: string) => {
  return `${format(new Date(date), formatStr)}`;
};

export const yearDateFormat = (date: string) => {
  return `${format(new Date(date), 'yyy-MM-dd')}`;
};
